var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "uploader", staticClass: "model-uploader" }, [
    _c("div", { staticClass: "upload-container" }, [
      !_vm.$store.state.wallet.connected
        ? _c(
            "div",
            { staticClass: "upload-login text-center" },
            [
              _c("h4", { staticClass: "mt-3" }, [_vm._v("Login To Upload")]),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "mt-3 btn btn-primary",
                  attrs: { to: "/login" },
                },
                [_vm._v("Login")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.uploading,
              expression: "uploading",
            },
          ],
          staticClass: "upload-progress text-center",
        },
        [
          _c("h4", { staticClass: "my-3" }, [_vm._v("Uploading Files")]),
          _vm._v(" "),
          _c("i", { staticClass: "fa fa-5x fa-spinner fa-spin" }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.uploading,
              expression: "!uploading",
            },
          ],
          staticClass: "meeting-upload-info",
        },
        [
          _c("h4", { staticClass: "mt-3" }, [_vm._v("Upload File")]),
          _vm._v(" "),
          _c("h5", { staticClass: "blue mt-3" }, [
            _vm._v("Drag & Drop File Here"),
          ]),
        ]
      ),
      _vm._v(" "),
      !_vm.uploading
        ? _c("form", { attrs: { enctype: "multipart/form-data" } }, [
            _c("input", {
              ref: "file",
              staticClass: "file-upload",
              attrs: {
                id: "file-upload",
                type: "file",
                accept: ".glb,.fbx,.png,.jpg,.jpeg,.gif",
              },
              on: { change: _vm.filesChange },
            }),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }