<template>
	<div ref="uploader" class="model-uploader">
		<div class="upload-container">
			<div class="upload-login text-center" v-if="!$store.state.wallet.connected">
				<!--		<div class="upload-login">-->
				<h4 class="mt-3">Login To Upload</h4>
<!--				<Phantom class="btn btn-primary mt-3"></Phantom>-->
<!--				<MultiWallet class="mt-3"></MultiWallet>-->
				<router-link to="/login" class="mt-3 btn btn-primary">Login</router-link>
			</div>

			<div class="upload-progress text-center" v-show="uploading">
				<h4 class="my-3">Uploading Files</h4>
				<i class="fa fa-5x fa-spinner fa-spin"></i>
			</div>

			<div class="meeting-upload-info" v-show="!uploading">
				<h4 class="mt-3">Upload File</h4>
				<h5 class="blue mt-3">Drag & Drop File Here</h5>
			</div>
			<form enctype="multipart/form-data" v-if="!uploading">
				<input id="file-upload" ref="file" type="file" class="file-upload" accept=".glb,.fbx,.png,.jpg,.jpeg,.gif"
							 @change="filesChange">
			</form>
		</div>
	</div>
</template>

<script>
import {uuidv4} from "@alphabatem/js_sdk/src/common/utils";
import Meeting from "../../js/api/meeting";
import Phantom from "../wallet/Phantom";
import MultiWallet from "../wallet/MultiWallet";

export default {
	name: "ModelUploader",
	components: {MultiWallet, Phantom},
	props: {
		endpoint: {
			type: String,
			required: true
		},
		walletOverride: {
			type: String,
			required: false,
			default: null
		},
	},
	data() {
		return {
			dropzone: null,
			uploading: false,
			items: {}
		}
	},
	methods: {
		onDragOver(e) {
			e.stopPropagation();
			e.preventDefault()
			e.dataTransfer.effectAllowed = "move";
			this.visible = true;
		},

		onDragLeave(e) {
			if (e.pageX !== 0 || e.pageY !== 0) { //Stops flickering
				return false;
			}

			e.stopPropagation();
			e.preventDefault()
			// console.log('onDragLeave', e);
			this.visible = false;
		},

		filesChange: function () {
			const file = this.$refs.file.files[0]
			if (!file) {
				return
			}
			console.log("File dropped", file)

			// handle file changes
			const formData = new FormData();
			formData.append("file", file)

			file.uid = `${file.name}-${uuidv4()}`
			file.status = 'pending'
			file.uri = URL.createObjectURL(file);
			file.progress = 0


			this.items[file.uid] = file
			// save it
			this.upload(file.uid, formData, file);

			this.$emit("upload", file)
			this.visible = false;
		},

		upload: function (id, formData, file) {
			let walletAddr = this.$store.state.wallet.address
			if (walletAddr === "" || walletAddr === null || walletAddr === undefined) {
				this.$toastr.error("Please login to upload")
				return
			}

			if (this.walletOverride !== '' && this.walletOverride !== null && this.walletOverride !== undefined) {
				walletAddr = this.walletOverride
			}

			this.uploading = true;
			this.items[id].status = "uploading"


			this.$toastr.i("Uploading file, please wait")
			formData.append("wallet_addr", walletAddr)

			//TODO endpoint prop
			Meeting.uploadUserFile(formData).then(resp => {
				console.log("file uploaded: ", resp.data)

				this.$emit("uploaded", resp.data, walletAddr, file)
				this.$toastr.s("file uploaded")
				this.uploading = false;
				this.items[id].status = "uploaded"
			}).catch(err => {
				console.log("error uploading file: ", err)
				this.$toastr.e("Error uploading file")
				this.uploading = false;
				this.items[id].status = "error"
			})
		}
	},
	mounted() {
		this.$refs.uploader.addEventListener('dragover', this.onDragOver);
		this.$refs.uploader.addEventListener('dragleave', this.onDragLeave);
	},
	beforeDestroy() {
		this.$refs.uploader.removeEventListener('dragover', this.onDragOver)
		this.$refs.uploader.removeEventListener('dragleave', this.onDragLeave)
	},
}
</script>

<style scoped>
.model-uploader {
	height: 100%;
	width: 100%;
	background: rgba(0, 0, 0, 0.3);
	border: 3px dashed white;
}

.upload-container {
	position: relative;
	height: 100%;
}


.upload-login, .meeting-upload-info {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1;
}

.upload-login {
	z-index: 2;
	background: rgba(0, 0, 0, 0.9);
}

.file-upload {
	min-height: 200px;
}

.meeting-upload-info {
	text-align: center;
	width: 100%;
	font-size: 12px;
	pointer-events: none;
	top: 0;
	left: 0;
}

::-webkit-file-upload-button, ::file-selector-button {
	display: none;
}

.uploading-item h5 {
	font-size: 0.9em;
}

.blue {
	color: #00bfff;
}
</style>
